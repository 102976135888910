module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"Perla Kitchen & Bar","description":"Welcome to our Kitchen and Bar. We are a family run restaurant that thrives on good food, good service and a relaxing atmosphere.","start_url":"/","background_color":"rgb(13, 33, 70)","theme_color":"rgb(144, 225, 238)","display":"minimal-ui","icon":"src/images/browser-icon.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
